import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button, Modal, Form, Input, message } from 'antd';
import { EyeOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';

const MessageCont = () => {
  const [messages, setMessages] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [currentMessage, setCurrentMessage] = useState(null);

  useEffect(() => {
    fetchMessages();
  }, []);

  const fetchMessages = async () => {
    try {
      const response = await axios.get('https://boletrade.api.codenilesolutions.com/api/contmessages');
      setMessages(response.data);
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  const handleSubmit = async (values) => {
    try {
      const response = await axios.post('https://boletrade.api.codenilesolutions.com/api/contmessages', { text: values.text });
      message.success('Message added successfully');
      setMessages([...messages, response.data]);
      form.resetFields();
    } catch (error) {
      console.error('Error adding message:', error);
      message.error('Failed to add message');
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://boletrade.api.codenilesolutions.com/api/contmessages/${id}`);
      setMessages(messages.filter(message => message._id !== id));
      message.success('Message deleted successfully');
    } catch (error) {
      console.error('Error deleting message:', error);
      message.error('Failed to delete message');
    }
  };

  const handleUpdate = async () => {
    try {
      const response = await axios.patch(`https://boletrade.api.codenilesolutions.com/api/contmessages/${currentMessage._id}`, {
        text: form.getFieldValue('text'), // Assuming 'text' is the field name in your form
      });
      message.success('Message updated successfully');
      // Update the messages state with the updated message
      setMessages(messages.map(message => message._id === currentMessage._id ? response.data : message));
      setIsModalVisible(false);
      form.resetFields();
    } catch (error) {
      console.error('Error updating message:', error);
      message.error('Failed to update message');
    }
  };

  const handleEdit = (message) => {
    setCurrentMessage(message);
    form.setFieldsValue({ text: message.text }); // Assuming 'text' is the field name in your form
    setIsModalVisible(true);
  };

  const handleView = (record) => {
    Modal.info({
      title: 'View Message',
      content: (
        <div>
          <p><strong>Message:</strong> {record.text}</p>
        </div>
      ),
      onOk() {},
    });
  };

  const columns = [
    { title: 'Message', dataIndex: 'text', key: 'text' },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <div>
          <Button type="default" className='p-4 bg-blue-700 text-white'  icon={<EyeOutlined />} onClick={() => handleView(record)}>
            View
          </Button>
          <Button type="default" className='p-4 bg-green-800 text-white' icon={<EditOutlined />} onClick={() => handleEdit(record)}>
            Edit
          </Button>
          <Button type="default" className='p-4 bg-red-600 text-white' icon={<DeleteOutlined />} onClick={() => handleDelete(record._id)}>
            Delete
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div className="p-4">
      <h2 className="text-2xl font-semibold mb-4">Message Manager</h2>
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Form.Item name="text" label="Message" rules={[{ required: true, message: 'Please enter a message' }]}>
          <Input.TextArea rows={4} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">Add Message</Button>
        </Form.Item>
      </Form>

      <Table dataSource={messages} columns={columns} rowKey="_id" />

      <Modal
        title="Update Message"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={handleUpdate}
      >
        <Form form={form} onFinish={handleUpdate} layout="vertical">
          <Form.Item name="text" label="Message" rules={[{ required: true, message: 'Please enter a message' }]}>
            <Input.TextArea rows={4} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default MessageCont;
