import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Table, Button, Modal, Space, Form, Input, Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const { TextArea } = Input;

const AddNewsForm = () => {
  const [form] = Form.useForm();
  const [updateForm] = Form.useForm();
  const navigate = useNavigate();
  const [newsList, setNewsList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(3); // Number of items per page
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);
  const [currentNewsItem, setCurrentNewsItem] = useState(null);

  useEffect(() => {
    fetchNews();
  }, []);

  const fetchNews = async () => {
    try {
      const response = await axios.get('https://boletrade.api.codenilesolutions.com/api/news');
      setNewsList(response.data);
    } catch (error) {
      console.error('Error fetching news:', error);
    }
  };

  const handleSubmit = async (values) => {
    // Basic validation
    if (!values.title || !values.text || !values.image) {
      message.error('Please fill in all fields and upload an image');
      return;
    }

    // Prepare form data
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('text', values.text);
    formData.append('image', values.image[0].originFileObj);

    try {
      // Send POST request to backend to add news
      await axios.post('https://boletrade.api.codenilesolutions.com/api/submit-news', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      message.success('News added successfully');
      // Refresh news list after adding
      fetchNews();
      // Reset form fields after submission
      form.resetFields();
    } catch (error) {
      console.error('Error adding news:', error);
      message.error('Failed to add news');
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this news?')) {
      try {
        await axios.delete(`https://boletrade.api.codenilesolutions.com/api/news/${id}`);
        message.success('News deleted successfully');
        fetchNews(); // Refresh news list after deletion
      } catch (error) {
        console.error('Error deleting news:', error);
        message.error('Failed to delete news');
      }
    }
  };

  const handleView = (record) => {
    Modal.info({
      title: 'View News',
      width: 800,
      content: (
        <div>
          <p><strong>Title:</strong> {record.title}</p>
          <p><strong>Description:</strong> {record.text}</p>
          <p><strong>Image:</strong> <img src={`https://boletrade.api.codenilesolutions.com/${record.imageURL}`} alt={record.title} style={{ width: '100%', maxHeight: '400px' }} /></p>
        </div>
      ),
      onOk() {},
    });
  };

  const handleUpdate = (record) => {
    setCurrentNewsItem(record);
    updateForm.setFieldsValue({
      title: record.title,
      text: record.text,
      image: null, // Image upload field is handled separately
    });
    setIsUpdateModalVisible(true);
  };

  const handleUpdateSubmit = async (values) => {
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('text', values.text);
    if (values.image && values.image.length > 0) {
      formData.append('image', values.image[0].originFileObj);
    }

    try {
      // Send PUT request to backend to update news
      await axios.put(`https://boletrade.api.codenilesolutions.com/api/news/${currentNewsItem._id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      message.success('News updated successfully');
      // Refresh news list after updating
      fetchNews();
      // Close the update modal
      setIsUpdateModalVisible(false);
    } catch (error) {
      console.error('Error updating news:', error);
      message.error('Failed to update news');
    }
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: '25%',
      ellipsis: true,
    },
    {
      title: 'Description',
      dataIndex: 'text',
      key: 'text',
      ellipsis: true,
      render: (text) => (text.length > 300 ? `${text.substring(0, 300)}...` : text),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <Space size="middle">
          <Button type="default" className='bg-blue-600 p-2 text-white' onClick={() => handleView(record)}>
            View
          </Button>
          <Button type="default" className='bg-green-700 p-2 text-white'  onClick={() => handleUpdate(record)}>
            Update
          </Button>
          <Button type="default" className='bg-red-600 p-2 text-white' onClick={() => handleDelete(record._id)}>
            Delete
          </Button>

          
        </Space>
      ),
    },
  ];

  const paginationConfig = {
    current: currentPage,
    pageSize: pageSize,
    total: newsList.length,
    onChange: (page) => setCurrentPage(page),
    showSizeChanger: false,
  };

  const dataToShow = newsList.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  return (
    <div className="w-full mx-auto p-8 bg-white shadow-md rounded-md">
      <h2 className="text-2xl font-bold mb-4  text-blue-800">Add News</h2>
      <Form form={form} onFinish={handleSubmit} layout="vertical" className="space-y-4">
        <Form.Item
          name="title"
          label="Title"
          rules={[{ required: true, message: 'Please enter the title' }]}
        >
          <Input placeholder="Enter title" />
        </Form.Item>
        <Form.Item
          name="text"
          label="Description"
          rules={[{ required: true, message: 'Please enter the description' }]}
        >
          <TextArea rows={5} placeholder="Enter description" />
        </Form.Item>
        <Form.Item
          name="image"
          label="Image"
          valuePropName="fileList"
          getValueFromEvent={(e) => e.fileList}
          rules={[{ required: true, message: 'Please upload an image' }]}
        >
          <Upload
            listType="picture"
            maxCount={1}
            beforeUpload={() => false} // Disable default upload behavior
          >
            <Button icon={<UploadOutlined />}>Click to upload</Button>
          </Upload>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Add News
          </Button>
        </Form.Item>
      </Form>

      {/* Display added news in Ant Design table */}
      <div className="w-full mx-auto p-8 bg-white shadow-md rounded-md">
        <h2 className="text-2xl font-bold mb-4">Added News</h2>
        <Table
          columns={columns}
          dataSource={dataToShow}
          pagination={paginationConfig}
          rowKey="_id"
        />
      </div>

      {/* Update News Modal */}
      <Modal
        title="Update News"
        visible={isUpdateModalVisible}
        onCancel={() => setIsUpdateModalVisible(false)}
        footer={null}
      >
        <Form form={updateForm} onFinish={handleUpdateSubmit} layout="vertical" className="space-y-4">
          <Form.Item
            name="title"
            label="Title"
            rules={[{ required: true, message: 'Please enter the title' }]}
          >
            <Input placeholder="Enter title" />
          </Form.Item>
          <Form.Item
            name="text"
            label="Description"
            rules={[{ required: true, message: 'Please enter the description' }]}
          >
            <TextArea rows={5} placeholder="Enter description" />
          </Form.Item>
          <Form.Item
            name="image"
            label="Image"
            valuePropName="fileList"
            getValueFromEvent={(e) => e.fileList}
          >
            <Upload
              listType="picture"
              maxCount={1}
              beforeUpload={() => false} // Disable default upload behavior
            >
              <Button icon={<UploadOutlined />}>Click to upload</Button>
            </Upload>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Update News
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AddNewsForm;
