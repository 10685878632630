import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button, Modal, Form, Input, Upload, message } from 'antd';
import { UploadOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

const { TextArea } = Input;
const { confirm } = Modal;

const Products = () => {
  const [form] = Form.useForm();
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(5); // Number of items per page
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(null);

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await axios.get('https://boletrade.api.codenilesolutions.com/api/products');
      setProducts(response.data);
    } catch (error) {
      console.error('Error fetching Products', error);
    }
  };

  const handleSubmit = async (values) => {
    // Basic validation
    if (!values.name || !values.priceperkilo  || !values.image) {
      message.error('Please fill in all fields and upload an image');
      return;
    }

    // Prepare form data
    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('priceperkilo', values.priceperkilo);
    formData.append('priceperquntal', values.priceperquntal);
    formData.append('image', values.image[0].originFileObj);

    try {
      // Send POST request to backend to add product
      await axios.post('https://boletrade.api.codenilesolutions.com/api/products', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      message.success('Product added successfully');
      // Refresh products list after adding
      fetchProducts();
      // Reset form fields after submission
      form.resetFields();
    } catch (error) {
      console.error('Error adding Product:', error);
      message.error('Failed to add Product');
    }
  };

  const handleDelete = (id, title) => {
    confirm({
      title: `Are you sure you want to delete "${title}"?`,
      icon: <ExclamationCircleOutlined />,
      content: 'This action cannot be undone.',
      onOk: async () => {
        try {
          await axios.delete(`https://boletrade.api.codenilesolutions.com/api/products/${id}`);
          message.success('Product deleted successfully');
          fetchProducts(); // Refresh products list after deletion
        } catch (error) {
          console.error('Error deleting Product:', error);
          message.error('Failed to delete Product');
        }
      },
      onCancel() {
        console.log('Delete canceled');
      },
    });
  };

  const handleView = (record) => {
    Modal.info({
      title: 'View Product',
      width: 800,
      content: (
        <div>
          
          <p><strong>Name:</strong> {record.name}</p>
          <p><strong>Price :</strong> {record.priceperkilo}</p>
          <p><strong>Price Per Quntal or Carton:</strong> {record.priceperquntal}</p>
          <p><strong>Image:</strong> <img src={`https://boletrade.api.codenilesolutions.com/${record.imageURL}`} alt={record.title} style={{ maxWidth: '100%', height: 'auto' }} /></p>
        </div>
      ),
      onOk() {},
    });
  };

  const handleUpdate = async (values) => {
    // Prepare form data
    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('priceperkilo', values.priceperkilo);
    formData.append('priceperquntal', values.priceperquntal);
    if (values.image && values.image.length > 0) {
      formData.append('image', values.image[0].originFileObj);
    }

    try {
      // Send PUT request to backend to update product
      await axios.put(`https://boletrade.api.codenilesolutions.com/api/products/${currentProduct._id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      message.success('Product updated successfully');
      // Refresh products list after updating
      fetchProducts();
      // Close update modal
      setIsUpdateModalVisible(false);
    } catch (error) {
      console.error('Error updating Product:', error);
      message.error('Failed to update Product');
    }
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Price ',
      dataIndex: 'priceperkilo',
      key: 'priceperkilo',
    },
    {
      title: 'Price Per Quntal or Carton',
      dataIndex: 'priceperquntal',
      key: 'priceperquntal',
    },
    {
      title: 'Image',
      dataIndex: 'imageURL',
      key: 'imageURL',
      render: (text, record) => (
        <img src={`https://boletrade.api.codenilesolutions.com/${text}`} alt={record.title} style={{ maxWidth: '100px', maxHeight: '100px', objectFit: 'cover' }} />
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <span>
          <Button type="link" onClick={() => handleView(record)}>View</Button>
          <Button type="link" onClick={() => showUpdateModal(record)}>Update</Button>
          <Button type="link" danger onClick={() => handleDelete(record._id, record.title)}>Delete</Button>
        </span>
      ),
    },
  ];

  const showUpdateModal = (record) => {
    setCurrentProduct(record);
    setIsUpdateModalVisible(true);
    form.setFieldsValue({
      
      name: record.name,
      priceperkilo: record.priceperkilo,
      priceperquntal: record.priceperquntal,
    });
  };

  const handleUpdateCancel = () => {
    setIsUpdateModalVisible(false);
    form.resetFields();
  };

  return (
    <div className='m-10'>
      <h1 className='font-bold text-2xl text-blue-800'>Products</h1>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        {/* <Form.Item name="title" label="Title">
          <Input />
        </Form.Item> */}
        <Form.Item name="name" label="Name">
          <Input />
        </Form.Item>
        <Form.Item name="priceperkilo" label="Price">
          <Input />
        </Form.Item>
        <Form.Item name="priceperquntal" label="Price Per Quntal/ Carton">
          <Input />
        </Form.Item>
        <Form.Item name="image" label="Image" valuePropName="fileList" getValueFromEvent={(e) => e.fileList}>
          <Upload name="image" listType="picture">
            <Button icon={<UploadOutlined />}>Upload Image</Button>
          </Upload>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">Add Product</Button>
        </Form.Item>
      </Form>

      <Table
        dataSource={products}
        columns={columns}
        pagination={{ pageSize: pageSize, total: products.length }}
        rowKey="_id"
      />

      <Modal
        title="Update Product"
        visible={isUpdateModalVisible}
        onCancel={handleUpdateCancel}
        footer={[
          <Button key="cancel" onClick={handleUpdateCancel}>Cancel</Button>,
          <Button key="submit" type="primary" onClick={() => form.submit()}>
            Update
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical" onFinish={handleUpdate}>
          <Form.Item name="name" label="Name">
            <Input />
          </Form.Item>
          <Form.Item name="priceperkilo" label="Price Per Kilo">
            <Input />
          </Form.Item>
          <Form.Item name="priceperquntal" label="Price Per Quntal">
            <Input />
          </Form.Item>
          <Form.Item name="image" label="Image" valuePropName="fileList" getValueFromEvent={(e) => e.fileList}>
            <Upload name="image" listType="picture">
              <Button icon={<UploadOutlined />}>Upload Image</Button>
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Products;
