import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Pagination, message as antdMessage, Modal, Button, Form, Input } from "antd";

const { TextArea } = Input;

const AddAnnouncement = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [announcements, setAnnouncements] = useState([]);
  const [current, setCurrent] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [viewModalVisible, setViewModalVisible] = useState(false);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);
  const [updateModalVisible, setUpdateModalVisible] = useState(false);
  const [form] = Form.useForm();

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const fetchAnnouncements = async (page) => {
    try {
      setLoading(true);
      const response = await axios.get(`https://boletrade.api.codenilesolutions.com/api/announcements?page=${page}&limit=3`);
      setAnnouncements(response.data.announcements);
      setTotal(response.data.total);
      setLoading(false);
    } catch (error) {
      antdMessage.error("Failed to fetch announcements.");
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("image", image);

    try {
      const response = await axios.post("https://boletrade.api.codenilesolutions.com/api/announcements", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.status === 201) {
        antdMessage.success("Announcement added successfully!");

        setTitle("");
        setDescription("");
        setImage(null);
        fetchAnnouncements(current); // Refresh the announcements list
      }
    } catch (error) {
      antdMessage.error("Failed to add announcement.");
      console.error("There was an error adding the announcement!", error);
    }
  };

  const handleUpdate = async () => {
    try {
      const values = await form.validateFields();
      const formData = new FormData();

      if (values.title) formData.append("title", values.title);
      if (values.description) formData.append("description", values.description);
      if (image) formData.append("image", image);

      const response = await axios.patch(`https://boletrade.api.codenilesolutions.com/api/announcements/${selectedAnnouncement._id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 200) {
        antdMessage.success("Announcement updated successfully!");
        setUpdateModalVisible(false);
        fetchAnnouncements(current); // Refresh the announcements list
      }
    } catch (error) {
      antdMessage.error("Failed to update announcement.");
      console.error("There was an error updating the announcement!", error);
    }
  };

  const handlePageChange = (page) => {
    setCurrent(page);
    fetchAnnouncements(page);
  };

  const handleView = (announcement) => {
    setSelectedAnnouncement(announcement);
    setViewModalVisible(true);
  };

  const handleDelete = async (id) => {
    Modal.confirm({
      title: "Confirm Delete",
      content: "Are you sure you want to delete this announcement?",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk: async () => {
        try {
          await axios.delete(`https://boletrade.api.codenilesolutions.com/api/announcements/${id}`);
          antdMessage.success("Announcement deleted successfully!");
          fetchAnnouncements(current);
        } catch (error) {
          antdMessage.error("Failed to delete announcement.");
          console.error("There was an error deleting the announcement!", error);
        }
      },
    });
  };

  useEffect(() => {
    fetchAnnouncements(current);
  }, [current]);

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (text) => (
        <div className="truncate" style={{ maxWidth: "160px" }}>{text}</div>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text) => (
        <div className="truncate" style={{ maxWidth: "300px" }}>{text}</div>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text) => (
        <div className="truncate" style={{ maxWidth: "160px" }}>{new Date(text).toLocaleDateString()}</div>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        
        <span>
          <Button onClick={() => handleView(record)} type="default" className='bg-blue-600 p-2 text-white'>View</Button>
          <Button onClick={() => {
            setSelectedAnnouncement(record);
            setUpdateModalVisible(true);
            form.setFieldsValue({
              title: record.title,
              description: record.description,
            });
          }} type="default" className='bg-green-700 p-2 text-white'>Update</Button>
          <Button onClick={() => handleDelete(record._id)} type="default" className='bg-red-600 p-2 text-white'>Delete</Button>
        </span>
      ),
    },
  ];


  return (
    <div className="w-full mx-auto p-6">
      <div className="mb-6">
        <h2 className="text-2xl mb-4">Add Announcement</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="title" className="block text-lg font-medium">Title</label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded"
              required
            />
          </div>
          <div>
            <label htmlFor="description" className="block text-lg font-medium">Description</label>
            <textarea
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded"
              rows="4"
              required
            />
          </div>
          <div>
            <label htmlFor="image" className="block text-lg font-medium">Image</label>
            <input
              type="file"
              id="image"
              accept="image/*"
              onChange={handleImageChange}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded">Add Announcement</button>
        </form>
      </div>

      <div>
        <h2 className="text-2xl mt-10 mb-6">Announcements</h2>
        <Table columns={columns} dataSource={announcements} rowKey="_id" loading={loading} pagination={false} />
        <Pagination
          current={current}
          onChange={handlePageChange}
          total={total}
          pageSize={3}
          className="mt-6 text-center"
        />
      </div>

      <Modal
        title="View Announcement"
        visible={viewModalVisible}
        onCancel={() => setViewModalVisible(false)}
        footer={[
          <Button key="close" onClick={() => setViewModalVisible(false)}>Close</Button>,
        ]}
      >
        {selectedAnnouncement && (
          <div>
            <h3>{selectedAnnouncement.title}</h3>
            <p>{selectedAnnouncement.description}</p>
            {selectedAnnouncement.image && (
              <img src={`https://boletrade.api.codenilesolutions.com/uploads/Announcement/${selectedAnnouncement.image}`} alt={selectedAnnouncement.title} className="max-w-full" />
            )}
          </div>
        )}
      </Modal>

      <Modal
        title="Update Announcement"
        visible={updateModalVisible}
        onCancel={() => setUpdateModalVisible(false)}
        onOk={handleUpdate}
      >
        <Form form={form} layout="vertical">
          <Form.Item name="title" label="Title">
            <Input placeholder="Enter new title" />
          </Form.Item>
          <Form.Item name="description" label="Description">
            <TextArea placeholder="Enter new description" rows={4} />
          </Form.Item>
          <Form.Item label="Image">
            <input type="file" accept="image/*" onChange={handleImageChange} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AddAnnouncement;
