import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { formatDistanceToNow } from "date-fns";
import Loader from "../Components/Loader/Loader";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { NavLink } from "react-router-dom";
const ProductsPage = () => {
  const { t, i18n } = useTranslation();
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true); // Loading state
  const [searchTerm, setSearchTerm] = useState(""); // State for search term
  const itemsPerPage = 12; // Displaying 6 items per page
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(
          "https://boletrade.api.codenilesolutions.com/api/products"
        );
        // Assuming products are already sorted by some criteria (e.g., date, popularity)
        setProducts(response.data);
        setLoading(false); // Set loading to false after data is fetched
      } catch (error) {
        console.error("Error fetching products:", error);
        setLoading(false); // Set loading to false even if there is an error
      }
    };

    fetchProducts();
  }, []); // Empty dependency array ensures this effect runs only once on component mount

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentProducts = products.slice(indexOfFirstItem, indexOfLastItem);

  // Function to truncate text
  const truncateText = (text, maxLength) => {
    if (!text) return ""; // Check if text is defined
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  // Change page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Handle search input change
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Filter products based on search term
  const filteredProducts = currentProducts.filter((product) =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleReadMore = (product) => {
    navigate(`/productdetail/${product._id}`, {
      state: {
        imageURL: product.imageURL,
        name: product.name,
        description: product.description,
      },
    });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return "Invalid date";
    }
    return formatDistanceToNow(date, { addSuffix: true });
  };

  return (
    <div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-8">
        <h2 className="text-6xl font-bold mb-6 text-center text-blue-800">
          {t("Products Price")}
        </h2>

        {/* Search input */}
        <div className="mb-4 flex justify-center">
          <div>
            <p className="inline font-bold text-2xl pr-3">{t("Search")}</p>
          </div>
          <div>
            <input
              type="text"
              placeholder="Search by product name"
              value={searchTerm}
              onChange={handleSearchChange}
              className="p-2 border-2 border-black rounded-md w-full max-w-md"
            />
          </div>
        </div>

        {loading ? (
          <div className="w-full min-h-screen">
            <Loader />
          </div>
        ) : (
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-3">
            {filteredProducts.length === 0 ? (
              <Loader />
            ) : (
              filteredProducts.map((product, index) => (
                <div
                  key={product._id}
                  className="border rounded-md shadow-md hover:shadow-lg transition duration-300"
                >
                  <img
                    src={`https://boletrade.api.codenilesolutions.com/${product.imageURL}`}
                    alt={product.name}
                    className="w-full h-60 object-cover rounded-t-md"
                  />
                  <div className="p-4">
                    <h3 className="text-xl font-semibold mb-2">
                      <span className="inline font-bold text-blue-800">
                        {t("Type of item")}:{" "}
                      </span>{" "}
                      <span className="font-bold">
                        {truncateText(product.name, 60)}
                      </span>
                    </h3>
                    <h3 className="text-xl font-semibold mb-2">
                      <span className="inline font-bold text-blue-800">
                        {t("Price")} :{" "}
                      </span>{" "}
                      <span className="font-bold">
                        {truncateText(product.priceperkilo, 60)}
                      </span>
                    </h3>
                    {product.priceperquntal && (
                      <h3 className="text-xl font-semibold mb-2">
                        <span className="inline font-bold text-blue-800">
                          {t("Price Per Quintal/Carton")}:{" "}
                        </span>
                        <span className="font-bold">
                          {truncateText(product.priceperquntal, 60)}
                        </span>
                      </h3>
                    )}
                  </div>
                </div>
              ))
            )}
          </div>
        )}
        {/* Pagination */}
        {!loading && products.length > itemsPerPage && (
          <div className="mt-6 flex justify-center">
            {[...Array(Math.ceil(products.length / itemsPerPage)).keys()].map(
              (pageNumber) => (
                <button
                  key={pageNumber + 1}
                  onClick={() => paginate(pageNumber + 1)}
                  className={`px-3 py-1 hover:text-blue-600 ${
                    currentPage === pageNumber + 1
                      ? "font-bold bg-yellow-500 text-white rounded-lg m-2"
                      : ""
                  }`}
                >
                  {pageNumber + 1}
                </button>
              )
            )}
          </div>
        )}
      </div>
      <div className="mb-10">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 mt-8">
          <div
            className="bg-yellow-100 border-l-4 border-yellow-500 p-4"
            role="alert"
          >
            <div className="flex">
              <div className="py-1">
                <svg
                  className="fill-current h-6 w-6 text-yellow-500 mr-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 2C5.03 2 1 6.03 1 11c0 4.97 4.03 9 9 9s9-4.03 9-9c0-4.97-4.03-9-9-9zM9 14a1 1 0 0 1-2 0V9a1 1 0 0 1 2 0v5zm2 0a1 1 0 0 1-2 0V9a1 1 0 0 1 2 0v5z"
                  />
                </svg>
              </div>
              <div>
                <p className="font-bold">{t("Notice")}</p>
                <p className="text-sm">{t("Alert Message")}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center p-4">
          <NavLink
            to="/complaint"
            className="bg-blue-800 text-white font-bold text-xl p-6"
          >
            {t("File a complaint")}
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default ProductsPage;
